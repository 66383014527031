import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
	name: "cliente-tema",
	components: {
		Banner
	},
	data() {
		return {
			arBreadcrumbs: [],
			blLoading: true,
			obTema: null,
			rolagemMenu: false
		};
	},
	mounted() {
		window.addEventListener("scroll", this.esconderMenuLateral);
	},
	watch: {
		"$route.params.slug": function (slug) {
			this.obter();
		}
	},
	created() {
		this.obter();
	},
	methods: {
		iniciarBreadcrumbs() {
			this.arBreadcrumbs = [{
				text: "Início",
				to: "/"
			}]
		},
		obter() {
			this.blLoading = true;
			this.iniciarBreadcrumbs();

			if (this.$route.params.idConteudo) {
				this.$root.$api
					.get(
						"tema/obterPorSlugComRascunho/" +
						this.$route.params.slug
					)
					.then(response => {
						this.obTema = response.retorno;
						this.blLoading = false;
						this.scrollMeTo();
					});
			} else {
				this.$root.$api
					.get("tema/obterPorSlug/" + this.$route.params.slug)
					.then(response => {
						this.obTema = response.retorno;
						this.blLoading = false;
						this.scrollMeTo();
					});
			}
		},

		titulosMenuLateral(titulo) {
			return this.$root.$utils.criaSlug(titulo);
		},

		scrollMeTo() {
			if (this.$route.hash) {
				this.$nextTick(() => {
					var element = this.$refs[this.$route.hash.replace("#", "")];
					var top = element[0].offsetTop;

					window.scrollTo(0, top);
				});
			}

			this.arBreadcrumbs.push({
				text: this.obTema.assunto.nome.replace('<br />', '').replace('<br>','').replace('<br/>', '')
			});

			this.arBreadcrumbs.push({
				text: this.obTema.nome.replace('<br />', '').replace('<br>','').replace('<br/>', '')
			});

		},

		esconderMenuLateral() {
			this.rolagemMenu = window.scrollY > 400 ? "block" : "none";
		},

		scrollando(event) {
			console.log(event);
		}
	}
};